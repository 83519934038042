<template>
    <div class="w-100">
        <Header />
        <div class="mt-6   p-3 pl-4">
            <div v-if="userJobs.length" class="h-user-jobs pb-3">
                <div class="user-card zc-w-20  my-2 zc-m-halfrem" v-for="(job, i) in userJobs" :key="i">
                    <b-row>
                        <b-col col cols="12" lg="8" md="12" sm="12">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex justify-content-between">
                                    <div style="margin-top: .5rem;">
                                        <b-avatar style="background: transparent;" rounded>
                                            <!-- <img :src="avtar"
                                                    width="75%" height="auto" class="img-fluid" /> -->
                                            <img :src="`${IMAGEURL}${job.company_logo_path}`" width="95%" height="95%"
                                                style="border-radius: 10px;" />
                                        </b-avatar>
                                    </div>
                                    <div class="px-3 align-items-center">
                                        <span style="cursor: pointer;" @click="gotoJobDetail(job.job_id)">
                                            <router-link :to="`/candidate/job-deatils/${job.job_id}`" class="h-job-name">
                                                {{ job.Job_title }}
                                            </router-link>
                                        </span>
                                        <div style="gap: 10px;" class="d-flex">
                                            <span class="h-company">{{ job.company }}</span>
                                            <span class="h-part-time">{{ job.jobType }}</span>
                                            <!-- <span class="h-exp">{{ job.designation }}</span> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <ul class="mt-3">
                                <!-- <li v-for="(desc, index) in job.jobDescription" :key="index" class="h-job-description">
                                    {{ desc.description }}
                                </li> -->
                                <!-- <li class="h-job-description">
                                    {{ job.jobDescription }}
                                </li> -->
                                <li class="h-job-description" v-for="(data, i) in job.jobDescription" :key="i">
                                    <!-- {{ job.jobDescription.split(".") }} -->
                                    {{ data }}
                                </li>
                            </ul>
                        </b-col>

                        <b-col col cols="12" lg="4" md="12" sm="12" class="top-bar">
                            <b-row>
                                <b-col col lg="12" md="6" sm="6" class="h-text-align">
                                    <div class="h-location">
                                        <img style="opacity: .5;" src="@/assets/zaajira-candidate/assets/icons/location.svg"
                                            class="img-fluid" />
                                        {{ job.country }}, {{ job.city }}
                                    </div>
                                    <div class="h-posted-at ml-md-1">
                                        {{ $gettext("Posted") }} {{ job.postedAt }}
                                    </div>
                                </b-col>
                                <b-col col lg="12" md="6" sm="6">
                                    <div class="mt-lg-3 h-opening">
                                        {{ job.numberOfOpenings }} {{ $gettext("Opening") }}
                                    </div>
                                    <div class="h-currency">
                                        {{ job.currency }} {{ job.job_salary_range_from }}-{{ job.job_salary_range_to }}/{{
                                            job.gender
                                        }}
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div v-if="infiniteScrollLoader" class="d-flex align-items-center justify-content-center">
                    <div class="infite-s-loader"> </div>
                </div>
                <div style="height:1rem;"></div>
            </div>
            <div v-else class='text-center'>
                <h2 style="font-size: clamp(1rem, 4vw,2rem);">{{ $gettext("There_are_no_saved_jobs") }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { formattedNumber, convertToTimeSince } from '../Helper/HelperFunctions.js'
import { IMAGEURL } from "@/config";
import avtar from '@/assets/zaajira-candidate/assets/bg-images/avtar-image.png'

export default {
    name: "SaveJob",
    components: { Header },
    data() {
        return {
            avtar,
            IMAGEURL,
            userJobs: [
                // {
                //     Job_title: "Senior ui designer",
                //     company: "Amazon",
                //     jobType: "Part-time",
                //     designation: "Senior level",
                //     country: "Pakistan",
                //     city: "Islamabad",
                //     postedAt: "Posted 1 hr ago",
                //     jobDescription: [
                //         {
                //             description: "Collaborate with the marketing team to optimize conversion.",
                //         },
                //         {
                //             description: " Develop inspiring, persuasive, and convincing copy for a wide array of writing needs.",
                //         }
                //     ],
                //     numberOfOpenings: 10,
                //     currency: " TZS 800,000 - 1,000,00",
                //     gender: "M"
                // },
            ],
            currentpage: 1,
            perPage: 10,
            totalJobs: null,
            infiniteScrollLoader: false,
        }
    },
    beforeMount() {
        this.PreFilledData()
    },
    methods: {
        gotoJobDetail(job_id) {
            console.log(job_id);
            this.$router.push({ path: `/candidate/job-deatils/${job_id}`, params: { job_id } });
        },
        async PreFilledData() {
            const loader = this.$loading.show()
            try {
                const res = await CandidateService.GetAllSavedJobs()
                const savedJobs = res.data.data
                savedJobs.map(async (sj) => {
                    const apJob = {
                        job_id: sj.job_id,
                        company_logo_path: sj.company_logo_path,
                        Job_title: sj.job_title,
                        company: sj.company_long_name,
                        jobType: sj.job_type,
                        designation: "Senior level",
                        country: sj.country[0].country_name,
                        city: sj.city[0].city_name,
                        postedAt: convertToTimeSince(sj.job_start_date),
                        jobDescription: sj.job_description.split(/[.•]/).filter((item, i) => item !== "" && item !== "\n" && i < 3),
                        numberOfOpenings: sj.vacancy,
                        currency: sj.currency_code,
                        job_salary_range_from: await formattedNumber(sj.job_salary_range_from),
                        job_salary_range_to: await formattedNumber(sj.job_salary_range_to),
                        gender: sj.gender === "Female" ? "F" : "M"
                    }
                    this.userJobs.push(apJob)
                })
            } catch (error) {
                console.log(error);
            }
            loader.hide()
        },
        infinitScrollForJobs(payload) {
            this.infiniteScrollLoader = true
            CandidateService.GetAllJobsOrFilter(payload)
                .then((res) => {
                    console.log("all jobs", res.data);
                    console.log("all jobs", res.data.data.data);
                    this.totalJobs = res.data.data.total
                    console.log("totalJobs", this.totalJobs);
                    const allJobs = res.data.data.data
                    let filterJob = []
                    if (allJobs.length) {
                        allJobs.map(async (aj) => {
                            const apJob = {
                                job_id: aj.job_id,
                                company_logo_path: aj.company_logo_path,
                                Job_title: aj.job_title,
                                company: aj.company_long_name,
                                jobType: aj.job_type,
                                designation: "Senior level",
                                country: aj.country,
                                city: aj.city_name,
                                postedAt: convertToTimeSince(aj.job_start_date),
                                jobDescription: aj.job_description.split(/[.•]/).filter((item, i) => item !== "" && item !== "\n" && i < 3),
                                numberOfOpenings: aj.vacancy,
                                currency: aj.currency_code,
                                job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
                                job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
                                gender: aj.gender === "Female" ? "F" : "M"
                            }
                            this.userJobs.push(apJob)
                        })
                    }
                    this.infiniteScrollLoader = false
                })
                .catch((error) => {
                    this.infiniteScrollLoader = false
                    console.log(error);
                })
        },
        handleScroll(e) {
            // console.log(e);
            // console.log("offsetHeight", e.target.offsetHeight);
            // console.log("scrollHeight", e.target.scrollHeight);
            // console.log("e.target.scrollTop", e.target.scrollTop);
            // console.log("scrollHeight - offsetHeight", e.target.scrollHeight - e.target.offsetHeight);
            const Scroll = e.target.scrollHeight - e.target.offsetHeight <= e.target.scrollTop
            if (Scroll && !this.infiniteScrollLoader && this.userJobs.length < this.totalJobs) {
                console.log("scrolled");
                this.currentpage = this.currentpage + 1

                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: this.currentpage,
                    perPage: this.perPage,
                    search: this.search,
                    filter: { filter: { filters: [] } }
                }
                console.log(payload);
                console.log("UserJobs", this.userJobs.length);
                console.log("totalJobs", this.totalJobs);
                this.infinitScrollForJobs(payload)

            }
        }
    },
    mounted() {
        const jobsDiv = document.getElementById('h-userjob')
        const mainSection = document.getElementById('main-section')
        if (window.innerWidth <= 992) {
            console.log("mainSection::Saved job", mainSection);
            mainSection.addEventListener('scroll', this.handleScroll);
        } else {
            jobsDiv.addEventListener('scroll', this.handleScroll);

        }
    },
    beforeDestroy() {
        if (window.innerWidth <= 992) {
            const mainSection = document.getElementById('main-section')
            mainSection.removeEventListener('scroll', this.handleScroll);
        } else {
            const jobsDiv = document.getElementById('h-userjob')
            jobsDiv.removeEventListener('scroll', this.handleScroll);
        }
    },
}
</script>

<style scoped>
.h-user-jobs {
    overflow: visible;
    height: 100%;
}

@media screen and (max-width:992px) {
    .zc-m-halfrem {
        margin: -0.5rem;
    }
}

@media screen and (max-width:360px) {
    .zc-m-halfrem {
        width: 21.5rem;
        margin: -1.2rem;
    }
}
</style>